import React, { useState, useEffect } from 'react';
import {useNavigate, Link} from 'react-router-dom';
import {
    auth,
    signInWithEmailAndPassword,
    signInWithPopup,
    GoogleAuthProvider,
    FacebookAuthProvider,
    facebookProvider,
    provider
} from '../../firebase';
// Images
import login from '../../assets/Login/1.JPG';
import login2 from '../../assets/Login/2.JPG';
import login3 from '../../assets/Login/3.JPG';
import login4 from '../../assets/Login/4.JPG';
import login5 from '../../assets/Login/5.JPG';
import login6 from '../../assets/Login/6.JPG';
import login7 from '../../assets/Login/7.JPG';
import login8 from '../../assets/Login/8.JPG';

import logo from '../../assets/login.PNG'

const Login = () => {
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [value , setValue] = useState("");
    const [backgroundImageIndex, setBackgroundImageIndex] = useState(0);
    const backgroundImages = [
        `url(${login})`,
        `url(${login2})`,
        `url(${login3})`,
        `url(${login4})`,
        `url(${login5})`,
        `url(${login6})`,
        `url(${login7})`,
        `url(${login8})`,
    ];

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            // Use state values directly since they're already being updated on change
            await signInWithEmailAndPassword(auth, email, password)
                .then((res)=> {
                    console.log("result: ",res.user.uid);
                    localStorage.setItem('userId', res.user.uid);
                    navigate('/home');
                })
                .catch((error)=>{
                    console.error('error: ',error);
                });
            // Redirect to dashboard or desired page after successful login
        } catch (error) {
            console.error("Error signing in with email and password", error);
        }
    };

    // const signInWithGoogle = async () => {
    //     try {
    //         signInWithPopup(auth,provider).then(data=>{
    //             console.log("result: ",data.user.uid);
    //             localStorage.setItem('userId', data.user.uid);
    //             setValue(data.user.email);
    //             localStorage.setItem("email",data.user.email);
    //             navigate('/home');
    //         })
    //     } catch (error) {
    //         console.error("Error signing in with Google", error);
    //     }
    // };
    // useEffect((()=>{
    //     setValue(localStorage.getItem('email'))
    // }))

    const signInWithGoogle = async () => {
        try {
            const result = await signInWithPopup(auth, provider);
            // Check if the user closed the popup
            if (!result.user) {
                // Handle the case where the user closed the popup
                console.log("Sign-in popup was closed by the user.");
                // You can display a message to the user or perform any other action
                return;
            }
            // If the user successfully signed in, proceed with your logic
            console.log("result: ", result.user.uid);
            localStorage.setItem('userId', result.user.uid);
            setValue(result.user.email);
            localStorage.setItem("email", result.user.email);
            navigate('/home');
        } catch (error) {
            console.error("Error signing in with Google", error);
        }
    };

    const signInWithFacebook = async () => {
        try {
            const result = await signInWithPopup(auth, facebookProvider);
            // Check if the user closed the popup
            if (!result.user) {
                // Handle the case where the user closed the popup
                console.log("Sign-in popup was closed by the user.");
                // You can display a message to the user or perform any other action
                return;
            }
            // If the user successfully signed in, proceed with your logic
            console.log("result: ", result.user.uid);
            localStorage.setItem('userId', result.user.uid);
            console.log('LoggedIn');
            navigate('/home');
        } catch (error) {
            console.error("Error signing in with Facebook", error);
        }
    };



    useEffect(() => {
        // Listen for authentication state changes
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                // User is signed in, redirect to '/'
                navigate('/');
            }
            // No need to handle the else case here since we're already on the login page
        });

        return () => unsubscribe(); // Cleanup subscription on unmount
    }, [navigate]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setBackgroundImageIndex((prevIndex) => (prevIndex + 1) % backgroundImages.length);
        }, 1500); // Change image every 1.5 seconds

        return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, [backgroundImages.length]);

    return (
        <div className="login-page" style={{ backgroundImage: backgroundImages[backgroundImageIndex] }}>
            <div className="login-container">
                <div className='row' style={{justifyContent:'center', width:'100%'}}>
                    <img src={logo} alt={logo} width='96px'/>
                    <div style={{textAlign:'left'}}>
                        <h2 style={{padding:0, margin:0}}>FM</h2>
                        <h4 style={{padding:0,margin:0}}>Friends Module</h4>
                    </div>

                </div>
                <h2>Login Here</h2>
                <form className="login-form" onSubmit={handleSubmit}>
                    <input
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <div style={{display:'flex', flexDirection:'column'}}>
                        <Link to='/forget-password' className="forgot-password">Forget password?</Link>
                        <Link to='/register' className="forgot-password">Register Now</Link>
                    </div>
                    <button onClick={handleSubmit} type="submit">Login</button>
                    <button className="google-btn" onClick={signInWithGoogle}>
                        Sign in with Google
                    </button>
                    <button className="facebook-btn" onClick={signInWithFacebook}>
                        Sign in with Facebook
                    </button>
                </form>
            </div>
            <footer>
                2024 Overheard YourRadio. All right reserved | Designed by Infigo
            </footer>
        </div>
    );
};

export default Login;

