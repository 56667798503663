import React, {useEffect, useState} from 'react';
import {fetchPosts, createPost, getUserProfile} from '../firebase';
// components
import HomeLeftComponent from "../components/HomeLeftComponent";
import HomeRightComponent from "../components/HomeRightComponent";
import StoriesComponent from "../components/Stories/StoriesComponent";
import PostComponent from "../components/Posts/PostComponent";
import Post from "../components/Posts/Post";

// image
import admin from '../assets/admin.png';
import pic from '../assets/Login/1.JPG';
function Home() {

    const userId = localStorage.getItem('userId');
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        const fetchAllPosts = async () => {
            const fetchedPosts = await fetchPosts();
            console.log(fetchedPosts);
            setPosts(fetchedPosts);
        };
        fetchAllPosts();
    }, []);

    const handleCreatePost = async (title, images, audio) => {
        const userId = localStorage.getItem('userId')
        const postId = await createPost(userId, title, images, audio);
        // Refresh posts or add the new post to the local state
    };

    return (
        <div className='home-container'>
            <div className='left-column'>
                <HomeLeftComponent/>
            </div>
            <div className='main-column'>
                <StoriesComponent/>
                <PostComponent onCreatePost={handleCreatePost}>
                    {posts.map(post => (
                        <Post
                            key={post.id}
                            id={post.id}
                            profilePhoto={post.profilePhoto || admin}
                            name={post.name || 'Unknown'}
                            date={new Date(post.createdAt.seconds * 1000).toLocaleDateString()}
                            title={post.title}
                            content={post.content}
                            image={post.imageUrls[0]}
                            audio={post.audioUrl}
                            likes={post.likes}
                            comments={post.comments}
                        />
                    ))}
                    {/* You can repeat the <Post /> component as many times as needed */}
                </PostComponent>
            </div>
            <div className='right-column'>
                <HomeRightComponent/>
            </div>
        </div>
    )
}

export default Home
