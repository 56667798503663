import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'semantic-ui-css/semantic.min.css'

// Screens
import Login from "./screens/login/Login";
import Register from "./screens/login/Register";
import Layout from "./screens/Layout";
import UserProfile from "./screens/UserProfile";
import Home from "./screens/Home";
import ForgetPassword from "./screens/login/ForgetPassword";
/* import Sample from "./screens/login/Sample"; */
import FriendRequests from "./screens/FriendRequests";

function App() {
  return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login/>}></Route>
          {/* <Route path="/sample" element={<Sample/>}></Route> */}
          <Route path="/register" element={<Register/>}></Route>
          <Route path="/forget-password" element={<ForgetPassword/>}></Route>
          <Route path="/home" element={<Layout/>}>
              <Route index element={<Home/>}/>
              <Route path="user/:userId" element={<UserProfile />} />
              <Route path="friend-request" element={<FriendRequests/>}/>
          </Route>
        </Routes>
      </BrowserRouter>
  );
}

export default App;
