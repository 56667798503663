import React, {useState, useEffect} from 'react'
import { collection, query, where, getDocs, orderBy, limit } from 'firebase/firestore';
import {db, fetchFriendRequests} from "../firebase";

const ACTIVE_THRESHOLD = 5 * 60 * 1000; // 5 minutes in milliseconds

function HomeRightComponent() {
    const [activeFriends, setActiveFriends] = useState([]);
    const userId = localStorage.getItem('userId');
    const [friendRequests, setFriendRequests] = useState([]);

    useEffect(() => {
        const fetchActiveFriends = async () => {
            // Define the logic for a user to be considered 'active'
            const activeThresholdDate = new Date(new Date().getTime() - ACTIVE_THRESHOLD);

            // Assuming you have a subcollection for a user's friends or a friends collection
            const friendsRef = collection(db, 'users', 'userId', 'friends'); // Replace 'userId' with the actual user ID
            const activeFriendsQuery = query(friendsRef, where('lastActive', '>=', activeThresholdDate));

            const querySnapshot = await getDocs(activeFriendsQuery);
            const friends = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
                isOnline: new Date(doc.data().lastActive.toDate()).getTime() > activeThresholdDate.getTime(),
            }));

            setActiveFriends(friends.filter(friend => friend.isOnline));
        };

        const fetchRequests = async() => {
            const requestData = await fetchFriendRequests(userId);
            setFriendRequests(requestData);
        }

        fetchActiveFriends();
        fetchRequests();
    }, []);
    return (
        <div style={{width: '100%', padding: 10, backgroundColor: "white"}}>
            <div className="ui icon input" style={{width: '100%'}}>
                <input type="text" placeholder="Search..."/>
                <i className="search icon"></i>
            </div>
            <h3 style={{textAlign: 'center'}}>Active Friends</h3>
            <hr/>
            <div className='right-friend-list'>
                {activeFriends.map(friend => (
                    <div key={friend.id} className='right-profile-detail-container'>
                        <div className='left-profile-photo'></div>
                        <h4 style={{margin: 0, paddingLeft: 10,}}>{friend.name} {friend.isOnline ? '(Online)' : ''}</h4>
                    </div>
                ))}
            </div>
            <h3 style={{textAlign: 'center'}}>Suggestions</h3>
            <hr/>
            <div className='right-friend-list'>
                <div className='right-profile-detail-container'>
                    <div className='left-profile-photo'></div>
                    <h4 style={{margin: 0, paddingLeft: 10,}}>Chethaka Lakshitha</h4>
                </div>
                <div className='right-profile-detail-container'>
                    <div className='left-profile-photo'></div>
                    <h4 style={{margin: 0, paddingLeft: 10,}}>Heshan Gamage</h4>
                </div>
                <div className='right-profile-detail-container'>
                    <div className='left-profile-photo'></div>
                    <h4 style={{margin: 0, paddingLeft: 10,}}>Imaduwa Lakshitha</h4>
                </div>
            </div>
            <h3 style={{textAlign: 'center'}}>Requests</h3>
            <hr/>
            <div className='right-friend-list'>
                {friendRequests.map(request=> (
                    <div className='right-profile-detail-container'>
                        <div className='left-profile-photo' style={{backgroundImage: `url(${request.profilePhoto})`}}></div>
                        <h4 style={{margin: 0, paddingLeft: 10,}}>{request.senderName}</h4>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default HomeRightComponent
