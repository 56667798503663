import React, { useState, useEffect } from 'react';
import {auth, createUserWithEmailAndPassword, sendEmailVerification} from '../../firebase';
import {useNavigate} from "react-router-dom";
import {registerUser} from "../../firebase";
import { CountrySelect, CitySelect } from 'react-country-state-city';
import 'react-phone-number-input/style.css';
import Modal from "react-modal";

// Images
import login from '../../assets/Login/1.JPG';
import login2 from '../../assets/Login/2.JPG';
import login3 from '../../assets/Login/3.JPG';
import login4 from '../../assets/Login/4.JPG';
import login5 from '../../assets/Login/5.JPG';
import login6 from '../../assets/Login/6.JPG';
import CountryCitySelector from "../../components/CountryCitySelector";
import {Circles} from "react-loader-spinner";

// Custom styles for the modal
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '50%', // Width of the modal
    },
};


const Register = () => {
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [name, setName] = useState("");
    const [lName, setLName] = useState("");
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [tel, setTel] = useState("");
    const [telCode, setTelCode] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [backgroundImageIndex, setBackgroundImageIndex] = useState(0);
    const backgroundImages = [
        `url(${login})`,
        `url(${login2})`,
        `url(${login3})`,
        `url(${login4})`,
        `url(${login5})`,
        `url(${login6})`,

    ];

    // useEffect(() => {
    //     const unsubscribe = auth.onAuthStateChanged((user) => {
    //         console.log("Auth state changed:", user);
    //         if (user) {
    //             console.log("User exists:", user.email, "Verified:", user.emailVerified);
    //             // Reload the user to get the latest state (in case the email was verified recently)
    //             user.reload().then(() => {
    //                 console.log("Reloaded user. Verified:", user.emailVerified);
    //                 if (user.emailVerified) {
    //                     console.log("Email is verified. Navigating to home page and closing modal.");
    //                     // Email is verified, so close the modal and navigate to the home page
    //                     setShowModal(false);
    //                     navigate('/');
    //                 } else {
    //                     // Email is not verified, so show the modal
    //                     console.log("Email is not verified. Showing modal.");
    //                     setShowModal(true);
    //                 }
    //             }).catch((error) => {
    //                 console.error("Error reloading user:", error);
    //             });
    //         } else {
    //             console.log("No user is signed in.");
    //         }
    //     });
    //
    //     // Cleanup subscription on component unmount
    //     return () => unsubscribe();
    // }, [navigate]);


    useEffect(() => {
        let checkEmailInterval; // Define the variable inside useEffect

        if (auth.currentUser && !auth.currentUser.emailVerified) {
            // Only set the interval if the user exists and email is not verified
            checkEmailInterval = setInterval(() => {
                checkEmailVerification();
            }, 2000); // Check every 5000 milliseconds (5 seconds)
        }

        return () => {
            clearInterval(checkEmailInterval); // Clear the interval when the component unmounts or the condition no longer meets
        };
    }, [auth.currentUser]);

    const checkEmailVerification = () => {
        auth.currentUser.reload().then(() => {
            if (auth.currentUser.emailVerified) {
                console.log("Email is verified. Navigating to home page and closing modal.");
                setShowModal(false);
                navigate('/');
                // No need to clear the interval here, it's handled by the useEffect cleanup
            } else {
                console.log("Waiting for email verification...");
            }
        }).catch(error => {
            console.error("Error reloading user:", error);
        });
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            // Create user
            await createUserWithEmailAndPassword(auth, email, password)
                .then((res) => {
                    console.log("userId: ", res.user.uid);
                    localStorage.setItem('userId', res.user.uid);
                    registerUser(res.user.uid, name + lName, email, tel + telCode);
                    // Send email verification
                    sendEmailVerification(res.user)
                        .then(() => {
                            console.log("Verification email sent successfully.");
                            // Set showModal to true to open the modal
                            setShowModal(true); // Show modal after successful registration and email sent
                        })
                        .catch((error) => {
                            console.error("Error sending verification email:", error);
                        });
                })
                .catch((e) => {
                    console.error('error: ', e);
                });
        } catch (error) {
            console.error("Error signing up with email and password", error);
        }
    };


    useEffect(() => {
        const intervalId = setInterval(() => {
            setBackgroundImageIndex((prevIndex) => (prevIndex + 1) % backgroundImages.length);
        }, 1500); // Change image every 1.5 seconds

        return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, [backgroundImages.length]);

    return (
        <div className="login-page" style={{ backgroundImage: backgroundImages[backgroundImageIndex] }}>
            <div className="register-container">
                <h2>Register</h2>
                <form className='ui form' >
                    <div className='field'>
                        <div className='two fields'>
                            <div className='eight wide field'>
                                <input
                                    type='text'
                                    placeholder="First Name"
                                    value={name}
                                    onChange={(e)=> setName(e.target.value)}
                                />
                            </div>
                            <div className='eight wide field'>
                                <input
                                    type='text'
                                    placeholder="Last Name"
                                    value={lName}
                                    onChange={(e)=> setLName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='two fields'>
                            <div className='eight wide field'>
                                <CountrySelect
                                    className='field'
                                    onChange={(country) => {
                                        setSelectedCountry(country);
                                        setSelectedCity(''); // Reset city when country changes
                                    }}
                                    value={selectedCountry}
                                    placeHolder="Select Country"
                                />
                            </div>
                            <div className='eight wide fieild'>
                                <input
                                    type="text"
                                    placeholder="City"
                                    style={{height:'50px'}}
                                    value={selectedCity}
                                    onChange={(e)=> setSelectedCity(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='two fields'>
                            <div className='four wide field'>
                                <input
                                    type="tel"
                                    placeholder="Tel code"
                                    value={telCode}
                                    onChange={(e)=> setTelCode(e.target.value)}
                                />
                            </div>
                            <div className='twelve wide field'>
                                <input
                                    type="tel"
                                    placeholder="Phone Number"
                                    value={tel}
                                    onChange={(e)=> setTel(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='two fields'>
                            <div className='eight wide field'>
                                <input
                                    type="email"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </div>
                            <div className='eight wide field'>
                                <input
                                    type="password"
                                    placeholder="Password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                            </div>
                        </div>
                        <button onClick={handleSubmit} type="submit">Register</button>
                    </div>
                </form>
            </div>
            <footer>
                2024 Overheard YourRadio. All right reserved | Designed by Chethaka
            </footer>
            {/* Modal component */}
            <Modal isOpen={showModal} onRequestClose={() => setShowModal(false)}  style={customStyles}>
                <div className='verify-modal'>
                    <h2>Please Verify Your Email</h2>
                    <p>A verification email has been sent to your email address. Please verify your email to continue.</p>
                    <Circles height={50} width={50} color='#feb800'/>
                    {/* Add any additional content or buttons here */}
                </div>
            </Modal>

        </div>
    );
};

export default Register;
