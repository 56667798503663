import React, {useState, useEffect} from 'react'
import {FcAdvertising, FcBusiness, FcBusinessman, FcCamera, FcCheckmark, FcDocument, FcFile} from "react-icons/fc";


// Images
import login from '../assets/Login/1.JPG';
import login2 from '../assets/Login/2.JPG';
import login3 from '../assets/Login/3.JPG';
import {fetchTrendingAudioPosts, getUserProfile} from "../firebase";
function HomeLeftComponent() {
    const userId = localStorage.getItem('userId');
    const [userData, setUserData] = useState(null);
    const [trendingData, setTrendingData] = useState(null);

    // Sample image data (replace these URLs with your actual image URLs)
    const imageData = [
        login,
        login2,
        login3,
        // Add more image URLs as needed
    ];

    useEffect(() => {
        // Fetch user data
        const fetchUserData = async () => {
            if(userId){
                const data = await getUserProfile(userId);
                setUserData(data);
            }
        };

        const fetchTrendingData = async () => {
            const trendingData = await fetchTrendingAudioPosts();
            setTrendingData(trendingData);
        }

        fetchTrendingData();
        fetchUserData();
    }, [userId]);

    if (!userData) {
        return <div>Loading...</div>;
    }

    return (
        <div style={{width: '100%'}}>
            {/*  About part  */}
            <div className='right-about-section'>
                <div className='left-profile-detail-container'>
                    <div className='right-profile-photo' style={{backgroundImage: `url(${userData.profilePhotoUrl})`}}></div>
                    <h3 style={{margin: 0, paddingLeft: 10,}}>{userData.name}</h3>
                </div>
                <hr style={{backgroundColor: 'black', height: 5, border: "none", marginTop: 20}}/>
                <h3 style={{textAlign: 'center', margin: 0}}>0 Posts . 0 friends</h3>
                <hr style={{backgroundColor: 'black', height: 5, border: "none", marginTop: 10}}/>
                <div className='right-button-section'>
                    <div className='right-btn-item'>
                        <FcFile size={34} style={{marginRight: 10}}/>
                        <p>Pages</p>
                    </div>
                    <div className='right-btn-item'>
                        <FcBusinessman size={34} style={{marginRight: 10}}/>
                        <p>Groups</p>
                    </div>
                    <div className='right-btn-item'>
                        <FcCamera size={34} style={{marginRight: 10}}/>
                        <p>Memories</p>
                    </div>
                    <div className='right-btn-item'>
                        <FcBusiness size={34} style={{marginRight: 10}}/>
                        <p>Recommendations</p>
                    </div>
                    <div className='right-btn-item'>
                        <FcCheckmark size={34} style={{marginRight: 10}}/>
                        <p>Saved</p>
                    </div>
                    <div className='right-btn-item'>
                        <FcDocument size={34} style={{marginRight: 10}}/>
                        <p>Create Page</p>
                    </div>
                </div>
            </div>

            <div className='right-trending-section'>
                <p style={{fontSize: '18px', fontWeight: 500}}>Trending Today</p>
                <hr style={{backgroundColor: '#ebebeb', height: 5, border: "none", marginTop: 10}}/>
                <div className='right-trending-container'>
                    {trendingData.map(trend => (
                        <div className='right-trending-item'>
                            <FcAdvertising size={34}/>
                            <p>{trend.title || "This is a sample Title and a description about the trending title"}</p>
                        </div>
                    ))}
                </div>
            </div>

            <div className='right-trending-section'>
                <p style={{fontSize: '18px', fontWeight: 500}}>Your Moments</p>
                <hr style={{backgroundColor: '#ebebeb', height: 5, border: "none", marginTop: 10}}/>
                <div className="image-grid">
                    {imageData.map((image, index) => (
                        <div key={index} className="image-item">
                            <img src={image} alt={`Image ${index}`}/>
                        </div>
                    ))}
                </div>
            </div>

        </div>
    )
}

export default HomeLeftComponent
