import React, {useEffect, useState} from 'react'
import {FaPlus} from "react-icons/fa";
import {FcBusinessman} from "react-icons/fc";
import {fetchStories} from "../../firebase";
import StoryUploadModal from "./StoryUploadModal";

function StoriesComponent() {
    const userId = localStorage.getItem('userId');
    const [stories, setStories] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentStoryIndex, setCurrentStoryIndex] = useState(null);

    useEffect(() => {
        const fetchStory = async() => {
            const storyData = await fetchStories(userId);
            console.log(storyData);
            setStories(storyData);
        }
        fetchStory();
    }, [userId]);

    const handleAddStoryClick = () => {
        setIsModalOpen(true); // Open the modal
    };

    const handleCloseModal = () => {
        setIsModalOpen(false); // Close the modal
    };

    const handleOpenStory = (index) => {
        setCurrentStoryIndex(index);
    };

    const handleCloseStory = () => {
        setCurrentStoryIndex(null);
    };

    const handleNextStory = () => {
        setCurrentStoryIndex((prevIndex) =>
            prevIndex < stories.length - 1 ? prevIndex + 1 : prevIndex
        );
    };

    const handlePreviousStory = () => {
        setCurrentStoryIndex((prevIndex) =>
            prevIndex > 0 ? prevIndex - 1 : 0
        );
    };

    return (
        <div className="100%">
            <div className='stories-container'>
                <div className='story-item'>
                    <div className='add-story' onClick={handleAddStoryClick}>
                        <FaPlus size={34}/>
                    </div>
                    <h5 style={{margin: 0}}>Add Story</h5>
                </div>
                {stories.map(story => (
                    <div className='story-item'>
                        <div className='story-item-profile'>
                            <FcBusinessman size={34}/>
                        </div>
                        <h5 style={{margin: 0}}>{story.name}</h5>
                    </div>
                ))}
            </div>
            <StoryUploadModal isOpen={isModalOpen} onClose={handleCloseModal}/>
        </div>
    )
}

export default StoriesComponent
