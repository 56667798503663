import React,{useState, useEffect} from 'react'
import {fetchFriendRequests, confirmFriendRequest, denyFriendRequest, fetchConfirmedFriends} from "../firebase";
import {useNavigate} from 'react-router-dom';

function FriendRequests() {
    const navigate = useNavigate();
    const userId = localStorage.getItem('userId');
    const [friendRequests, setFriendRequests] = useState([]);
    const [friends, setFriends] = useState([]);

    useEffect(() => {
        const loadFriendRequests = async () => {
            const requests = await fetchFriendRequests();
            setFriendRequests(requests);
        };

        loadFriendRequests();
    }, []); // Pass an empty dependency array to ensure this runs only once when the component mounts
    // Passing an empty dependency array to ensure this runs only once when the component mounts

    useEffect(() => {
        const loadFriends = async () => {
            const friendsList = await fetchConfirmedFriends();
            setFriends(friendsList);
        };

        loadFriends();
    }, []);

    const navigateToProfile = (userId) => {
        navigate(`/home/user/${userId}`);
    };

    const handleConfirmFriendRequest = async (requestId, senderId) => {
        await confirmFriendRequest(requestId);
        // Remove the confirmed request from the friendRequests state
        setFriendRequests(prevRequests => prevRequests.filter(request => request.id !== requestId));
        // Add the confirmed friend to the friends state
        const confirmedFriend = friendRequests.find(request => request.id === requestId);
        if (confirmedFriend) {
            setFriends(prevFriends => [...prevFriends, {
                id: confirmedFriend.senderId,
                name: confirmedFriend.senderName,
                profilePhotoUrl: confirmedFriend.profilePhoto // Assuming this is how you store profile photo URL
            }]);
        }
    };

    const handleDenyFriendRequest = async (requestId) => {
        await denyFriendRequest(requestId);
        // Remove the denied request from the friendRequests state
        setFriendRequests(prevRequests => prevRequests.filter(request => request.id !== requestId));
    };

    return (
        <div>
            <div className='friend-request-container'>
                <h2 >Friend Requests</h2>
                {friendRequests.map(request => (
                    <div key={request.id} className='friend-request'>
                        <div className='friend-detail'>
                            <img className='friendPhoto' src={request.profilePhoto}/>
                            <p>{request.senderName}</p>
                        </div>

                        <div style={{width:'20%', display:'flex',justifyContent:'space-evenly'}}>
                            <button onClick={() => handleConfirmFriendRequest(request.id, userId)}>Confirm</button>
                            <button onClick={() => handleDenyFriendRequest(request.id)}>Deny</button>
                        </div>

                    </div>
                ))}
            </div>
            <div className='ui divider'></div>
            <div className='friends-list-container'>
                <h2>Friends ({friends.length})</h2>
                <div className='friends-list'>
                    {friends.map(friend => (
                        <div key={friend.id} className='friend' onClick={() => navigateToProfile(friend.id)}>
                            <img className='friendPhoto' src={friend.profilePhotoUrl} alt={friend.name}/>
                            <p>{friend.name}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default FriendRequests
