import React, { useState, useEffect } from 'react';
import {auth, sendPasswordResetEmail} from '../../firebase';
import {useNavigate} from "react-router-dom";

// Images
import login from '../../assets/Login/1.JPG';
import login2 from '../../assets/Login/2.JPG';
import login3 from '../../assets/Login/3.JPG';
import login4 from '../../assets/Login/4.JPG';
import login5 from '../../assets/Login/5.JPG';
import login6 from '../../assets/Login/6.JPG';

const ForgetPassword = () => {
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [backgroundImageIndex, setBackgroundImageIndex] = useState(0);
    const backgroundImages = [
        `url(${login})`,
        `url(${login2})`,
        `url(${login3})`,
        `url(${login4})`,
        `url(${login5})`,
        `url(${login6})`,
    ];

    const handleSubmit = (e) => {
        e.preventDefault();
        sendPasswordResetEmail(auth, email) // Call sendPasswordResetEmail with auth and email
            .then(() => {
                console.log("Password Reset Sent to ",email);
                navigate('/');
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage);
            });
    };


    useEffect(() => {
        const intervalId = setInterval(() => {
            setBackgroundImageIndex((prevIndex) => (prevIndex + 1) % backgroundImages.length);
        }, 1500); // Change image every 1.5 seconds

        return () => clearInterval(intervalId);
    }, [backgroundImages.length]);

    return (
        <div className="login-page" style={{ backgroundImage: backgroundImages[backgroundImageIndex] }}>
            <div className="login-container">
                <h2>Forget Your Password</h2>
                <form className="login-form">
                    <input
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <button onClick={handleSubmit} type="submit">Send</button>
                </form>
            </div>
            <footer>
                2024 Overheard YourRadio. All right reserved | Designed by Chethaka
            </footer>
        </div>
    );
};

export default ForgetPassword;
