import React, { useState } from 'react';
import { FaMicrophone, FaStop, FaCamera, FaPaperPlane } from 'react-icons/fa';

function PostComponent({ onCreatePost, children }) {
    const [title, setTitle] = useState('');
    const [images, setImages] = useState([]);
    const [audio, setAudio] = useState(null);
    const [isRecording, setIsRecording] = useState(false);
    const [mediaRecorder, setMediaRecorder] = useState(null);

    const handleTitleChange = (e) => {
        setTitle(e.target.value);
    };

    const handleImageChange = (e) => {
        setImages([...e.target.files]);
    };

    const startRecording = async (e) => {
        e.preventDefault();
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            const recorder = new MediaRecorder(stream);
            let chunks = [];

            recorder.onstart = () => {
                setIsRecording(true);
                chunks = [];
            };

            recorder.ondataavailable = (e) => {
                chunks.push(e.data);
            };

            recorder.onstop = async () => {
                const audioBlob = new Blob(chunks, { type: 'audio/mpeg' });
                setAudio(audioBlob);
                stream.getTracks().forEach(track => track.stop());
            };

            recorder.start();
            setMediaRecorder(recorder);
        } catch (error) {
            console.error('Error starting audio recording: ', error);
        }
    };

    const stopRecording = (e) => {
        e.preventDefault();
        if (mediaRecorder) {
            mediaRecorder.stop();
            setIsRecording(false);
        }
    };

    const handleAudioCancel = (e) => {
        e.preventDefault();
        setAudio(null);
        setIsRecording(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!title || (!images.length && !audio)) {
            alert("Please provide a title and at least an image or an audio.");
            return;
        }
        await onCreatePost(title, images, audio);
        // Reset form fields after post creation
        setTitle('');
        setImages([]);
        setAudio(null);
    };


    return (
        <div style={{ width: '100%', minHeight: '100vh', marginTop: '1rem' }}>
            <div className='post-creation-container'>
                <h5>Make a Post</h5>
                <form>
                    <div className="ui action input post-create">
                        <input
                            type="text"
                            placeholder="Title"
                            value={title}
                            onChange={handleTitleChange}
                        />
                        <label htmlFor="image-upload" className="ui icon button" style={{ width: '5%', display:"flex", alignItems:'center', justifyContent:'center' }}>
                            <FaCamera />
                            <input
                                id="image-upload"
                                type="file"
                                accept="image/*"
                                multiple
                                onChange={handleImageChange}
                                hidden
                            />
                        </label>
                        {isRecording ? (
                            // <FaStop size={34} onClick={stopRecording} />
                            <button className="ui icon button" style={{ width: '5%', display:"flex", alignItems:'center', justifyContent:'center' }} onClick={stopRecording}>
                                <i className="stop icon"></i>
                            </button>
                        ) : (
                            // <FaMicrophone size={34} onClick={startRecording} />
                            <button className="ui icon button" style={{ width: '5%', display:"flex", alignItems:'center', justifyContent:'center' }} onClick={startRecording}>
                                <i className="microphone icon"></i>
                            </button>
                        )}
                    </div>
                    {audio && (
                        <div className="audio-preview">
                            <audio src={URL.createObjectURL(audio)} controls/>
                            <button className="ui icon button" style={{
                                width: '5%',
                                display: "flex",
                                alignItems: 'center',
                                justifyContent: 'center'
                            }} onClick={handleAudioCancel}>
                                <i className="trash icon"></i>
                            </button>
                            {/*<button type="submit"><FaPaperPlane size={34}/></button>*/}
                            <button className="ui icon button" style={{
                                width: '5%',
                                display: "flex",
                                alignItems: 'center',
                                justifyContent: 'center'
                            }} onClick={handleSubmit}>
                                <i className="send icon"></i>
                            </button>
                        </div>
                    )}
                </form>
            </div>
            <div className="post-layout">
                {children}
            </div>
        </div>
    );
}

export default PostComponent;
