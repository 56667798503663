import React, {useState, useEffect} from 'react';
import {FaThumbsUp} from "react-icons/fa";
import {addCommentToPost, getUserProfile, toggleLikePost} from "../../firebase";
import {FcBusinessman} from "react-icons/fc";

function Post({ id ,profilePhoto, name, date, title, content, image, audio, likes, comments=[] }) {
    const [showComments, setShowComments] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const audioRef = React.useRef(); // Changed to `useRef` for better convention
    const userId = localStorage.getItem('userId');
    const [newComment, setNewComment] = useState('');
    const [commentProfiles, setCommentProfiles] = useState({});

    useEffect(() => {
        const fetchCommenterProfiles = async () => {
            const profiles = {};
            for (const comment of comments) {
                if (!profiles[comment.userId]) {
                    profiles[comment.userId] = await getUserProfile(comment.userId);
                }
            }
            setCommentProfiles(profiles);
        };

        if (Array.isArray(comments)) {
            fetchCommenterProfiles();
        }
    }, [comments]);

    const togglePlay = () => {
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.pause();
            } else {
                audioRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    };

    const handleLike = async () => {
        await toggleLikePost(id, userId);
        // Optionally: Refresh post data or update local state to reflect the new like status
    };

    const handleAddComment = async () => {
        const comment = {
            userId: userId,
            text: newComment,
            date: new Date().toISOString(),
            // Add other details as needed
        };

        await addCommentToPost(id, comment);
        setNewComment(''); // Reset input after submitting
        // Optionally refresh comments or update local state
    };

    const isLikedByCurrentUser = Array.isArray(likes) && likes.includes(userId);

    return (
        <div className="post">
            <div className="post-header">
                <img src={profilePhoto} alt={`${name}'s profile`} className="profile-photo" />
                <div>
                    <h5 style={{margin:0}}>{name}</h5>
                    <p className="post-date">{date}</p>
                </div>
            </div>
            <img src={image} alt={title} className="post-image" />
            <h2>{title}</h2>
            <p>{content}</p>
            {audio && (
                <>
                    <audio ref={audioRef} src={audio} controls hidden /> {/* Attached the ref here */}
                    <button className="play-button" onClick={togglePlay}>
                        <i className={`icon ${isPlaying ? 'pause' : 'play'}`} />
                        {isPlaying ? 'Pause' : 'Play'}
                    </button>
                </>
            )}
            <div className='row' style={{padding: 10}}>
                <FaThumbsUp onClick={handleLike} size={18} style={{marginRight: 10}}/>
            </div>
            <div className="post-reactions">
                <span>{likes.length || '0'} Likes</span>
                <span onClick={() => setShowComments(!showComments)}>{comments.length} Comments</span>
            </div>
            {showComments && (
                <div className="comments-section">
                {comments.map((comment, index) => (
                        <div key={index} className="commentn row" style={{marginBottom:5}}>
                            <img src={commentProfiles[comment.userId]?.profilePhoto || <FcBusinessman/>} alt="Profile" style={{ width: '30px', height: '30px', borderRadius: '50%' }} />
                            <p>{comment.text}</p>
                        </div>
                    ))}

                    <div className="ui action input" style={{ width: '100%' }}>
                        <input
                            type="text"
                            value={newComment}
                            onChange={(e) => setNewComment(e.target.value)}
                            placeholder="Add a comment..."
                        />
                        <button className="ui icon button" onClick={handleAddComment} style={{ width: '10%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <i className="send icon"></i>
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Post;