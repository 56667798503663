// StoryUploadModal.js
import React, { useState } from 'react';
import { uploadStory } from '../../firebase'; // Assume this function handles the upload process

function StoryUploadModal({ isOpen, onClose }) {
    const [file, setFile] = useState(null);
    const userId = localStorage.getItem('userId');

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (file) {
            await uploadStory(userId,file);
            onClose(); // Close modal after upload
        }
    };

    if (!isOpen) return null;

    return (
        <div className={`modal-overlay ${isOpen ? 'open' : ''}`}>
            <div className="modal-content">
                <form className='ui form' onSubmit={handleSubmit}>
                    <div className='field'>
                        <label htmlFor="story-upload">Upload a story:</label>
                        <input
                            id="story-upload"
                            type="file"
                            accept="image/*,video/*"
                            onChange={handleFileChange}
                            required
                        />
                    </div>
                    <button type="submit">Upload</button>
                    <button type="button" onClick={onClose}>Cancel</button>
                </form>
            </div>
        </div>
    );
}

export default StoryUploadModal
