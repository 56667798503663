// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {
    createUserWithEmailAndPassword,
    FacebookAuthProvider,
    getAuth,
    GoogleAuthProvider,
    sendEmailVerification,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut
} from "firebase/auth";
import {
    addDoc,
    collection,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    getFirestore,
    limit,
    orderBy,
    query,
    runTransaction,
    setDoc,
    updateDoc,
    where
} from "firebase/firestore";
import {getDownloadURL, getStorage, ref, uploadBytes} from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDs-3Q9e3WmVJfj99tX24mZ0XGaPGWnOz8",
    authDomain: "overhead-yourradio.firebaseapp.com",
    databaseURL: "https://overhead-yourradio-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "overhead-yourradio",
    storageBucket: "overhead-yourradio.appspot.com",
    messagingSenderId: "458908760321",
    appId: "1:458908760321:web:3a18833bde2b4cfaf64300",
    measurementId: "G-18BN6HMTM6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth();
const provider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

// Storage
const storage = getStorage(app, "gs://overhead-yourradio.appspot.com");
// Create a storage reference from our storage service
const storageRef = ref(storage);
// Create a child reference
const imagesRef = ref(storage, 'images');
// imagesRef now points to 'images'




// //  Functions

// Register User
const registerUser = async(userId,fullName,userEmail,userPhone) => {
    try{
        const docRef = await setDoc(doc(db, 'users', userId),{
            name:fullName,
            email:userEmail,
            phone:userPhone
        })
    }catch (e) {
        console.error("Error adding document: ",e);
    }
}

const userProfile = async(
    userId,
    name,
    email,
    phone,
    userBio,
    userLocation,
    userLifeEvents,
    aboutDescription,
    userExperience,
    userEducation,
    userSkills,
    userAccomplishments
) => {
    try{
        const docRef = await setDoc(doc(db, 'users', userId),{
            name,
            email,
            phone,
            bio: userBio,
            location: userLocation,
            lifeEvents: userLifeEvents,
            about_description: aboutDescription,
            experience: userExperience,
            education: userEducation,
            skills: userSkills,
            accomplishments: userAccomplishments
        })
    }catch (e) {
        console.error("Error adding document: ", e)
    }
}

const getUserProfile = async (userId) => {
    try {
        const docRef = doc(db, 'users', userId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            console.log("Document data:", docSnap.data()); // Call data() as a function
            return docSnap.data(); // Return the document data
        } else {
            console.log("No such document!");
        }
    } catch (e) {
        console.error("Error retrieving document: ", e);
    }
};

const uploadProfilePhotoFunc = async (userId,file) => {
    const storageRef = ref(storage, `profilePhotos/${userId}`);
    try {
        await uploadBytes(storageRef, file);
        const url = await getDownloadURL(storageRef);
        const profileRef = doc(db, 'users', userId);
        await updateDoc(profileRef, {
            profilePhotoUrl: url
        });
    } catch (error) {
        console.error("Error uploading profile photo: ", error);
    }
};

const uploadBannerPhotoFunc = async (userId, file) => {
    const storageRef = ref(storage, `banners/${userId}`);
    try{
        await uploadBytes(storageRef, file);
        const url = await getDownloadURL(storageRef);
        const bannerRef = doc(db,'users',userId);
        await updateDoc(bannerRef, {
           bannerUrl: url
        });
    }catch(error) {
        console.error("Error uploading banner Image: ", error);
    }
};



// Search function
const getSearchResult = async (searchQuery) => {
    if (searchQuery.length > 1) {
        try {
            const userRef = collection(db, 'users');
            const q = query(userRef, where('name', '>=', searchQuery), where('name', '<=', searchQuery + '\uf8ff'));
            const querySnapshot = await getDocs(q);
            const results = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            console.log("firebase Results:", results); // Log the search results
            return results;
        } catch (error) {
            console.error("Error fetching search results: ", error);
            return [];
        }
    } else {
        return []; // Return an empty array if the search query is too short
    }
};
// Friend Request Functions
const sendFriendRequest = async (recipientId) => {
    const userId = localStorage.getItem('userId');
    const request = {
        senderId: userId,
        recipientId,
        status: 'pending',
        createdAt: new Date()
    };

    try {
        // Add the friend request to your database
        // This will automatically generate a new document with a unique ID within the 'friend-requests' collection
        const docRef = await addDoc(collection(db, 'friend-requests'), request);
        console.log('Friend request sent with ID: ', docRef.id);
    } catch (error) {
        console.error('Error sending friend request: ', error);
    }
};


const confirmFriendRequest = async (requestId, senderId) => {
    const requestRef = doc(db, 'friend-requests', requestId);
    await updateDoc(requestRef, { status: 'confirmed' });
    // Optionally, add both users to each other's friends collection
};

// Denies a friend request and removes it from Firestore
const denyFriendRequest = async (requestId) => {
    const requestRef = doc(db, 'friend-requests', requestId);
    await deleteDoc(requestRef); // This will remove the document from Firestore
};

const fetchFriendRequests = async () => {
    const userId = localStorage.getItem('userId'); // Get the current user's ID
    if (!userId) {
        console.error("User is not logged in");
        return []; // Return an empty array if the user is not logged in
    }

    try {
        const q = query(collection(db, 'friend-requests'), where('recipientId', '==', userId), where('status', '==', 'pending'));
        const querySnapshot = await getDocs(q);

        const friendRequests = await Promise.all(querySnapshot.docs.map(async (friendRequestDoc) => {
            const requestData = friendRequestDoc.data();
            const senderProfileDoc = await getDoc(doc(db, 'users', requestData.senderId));

            if (senderProfileDoc.exists()) {
                return {
                    id: friendRequestDoc.id,
                    senderId: requestData.senderId,
                    senderName: senderProfileDoc.data().name,
                    profilePhoto: senderProfileDoc.data().profilePhotoUrl,
                    status: requestData.status,
                    createdAt: requestData.createdAt
                };
            } else {
                return null; // If the sender's profile is not found, return null and filter it out later
            }
        }));

        // Filter out any null values
        return friendRequests.filter(request => request !== null);
    } catch (error) {
        console.error('Error fetching friend requests: ', error);
        return []; // Return an empty array in case of error
    }
};

const fetchFriendRequestsCount = async () => {
    const userId = localStorage.getItem('userId');
    const q = query(collection(db, 'friend-requests'), where('recipientId', '==', userId), where('status', '==', 'pending'));
    const querySnapshot = await getDocs(q);
    return querySnapshot.size; // Returns the count of documents
};

const fetchConfirmedFriends = async () => {
    const userId = localStorage.getItem('userId');
    const received = query(collection(db, 'friend-requests'), where('recipientId', '==', userId), where('status', '==', 'confirmed'));
    const sent = query(collection(db, 'friend-requests'), where('senderId', '==', userId), where('status', '==', 'confirmed'));

    const [receivedSnapshot, sentSnapshot] = await Promise.all([getDocs(received), getDocs(sent)]);
    const friendIds = new Set(); // Use a Set to avoid duplicates

    receivedSnapshot.forEach(doc => {
        friendIds.add(doc.data().senderId);
    });
    sentSnapshot.forEach(doc => {
        friendIds.add(doc.data().recipientId);
    });

    const friends = await Promise.all([...friendIds].map(async friendId => {
        const friendDoc = await getDoc(doc(db, 'users', friendId));
        if (friendDoc.exists()) {
            return { id: friendId, ...friendDoc.data() };
        } else {
            return null;
        }
    }));

    return friends.filter(friend => friend !== null);
};

// Function to upload an image and get the URL
const uploadImageAndGetUrl = async (userId, imageFile) => {
    const imageRef = ref(storage, `posts/${userId}/images/${imageFile.name}`);
    await uploadBytes(imageRef, imageFile);
    const imageUrl = await getDownloadURL(imageRef);
    return imageUrl;
};

// Function to upload audio and get the URL
const uploadAudioAndGetUrl = async (userId, audioFile) => {
    const audioRef = ref(storage, `posts/${userId}/audio/${audioFile.name}`);
    await uploadBytes(audioRef, audioFile);
    const audioUrl = await getDownloadURL(audioRef);
    return audioUrl;
};

export const createPost = async (userId, title, images, audio) => {
    try {
        // Upload images and audio to Firebase Storage and get their URLs
        const imageUrls = await Promise.all(images.map((image) => uploadImageAndGetUrl(userId, image)));
        const audioUrl = audio ? await uploadAudioAndGetUrl(userId, audio) : null;

        // Create a new post in Firestore
        const postRef = collection(db, 'posts');
        const newPost = {
            userId,
            title,
            imageUrls,
            audioUrl,
            createdAt: new Date(),
            likes: [],
            unlikes: [],
            comments: [] // Assuming you'll have a structure for comments
        };
        const postSnapshot = await addDoc(postRef, newPost);

        console.log('Post created with ID: ', postSnapshot.id);
        return postSnapshot.id; // Return the post ID
    } catch (error) {
        console.error('Error creating post: ', error);
        throw error;
    }
};

// Function to fetch posts from Firestore
// export const fetchPosts = async () => {
//     try {
//         const posts = [];
//         const querySnapshot = await getDocs(collection(db, 'posts'));
//
//         querySnapshot.forEach((doc) => {
//             posts.push({
//                 id: doc.id,
//                 ...doc.data(),
//             });
//         });
//         console.log("post: ", posts);
//         return posts;
//     } catch (error) {
//         console.error('Error fetching posts: ', error);
//         throw error;
//     }
// };


export const fetchPosts = async () => {
    try {
        const posts = [];
        const querySnapshot = await getDocs(collection(db, 'posts'));

        for (const doc of querySnapshot.docs) {
            const postData = doc.data();
            // Log to see what userId you are working with
            console.log("Fetching profile for userId:", postData.userId);

            const userProfile = await getUserProfile(postData.userId);

            // Log to see if userProfile is undefined
            if (!userProfile) {
                console.log("No user profile found for userId:", postData.userId);
            }

            // Provide a default photo if userProfile or profilePhotoUrl is not found
            const profilePhoto = userProfile?.profilePhotoUrl ;
            const name = userProfile?.name || 'Unknown User';

            posts.push({
                id: doc.id,
                profilePhoto,
                name,
                ...postData,
            });
        }

        return posts;
    } catch (error) {
        console.error('Error fetching posts: ', error);
        throw error;
    }
};



// Function to like a post
export const toggleLikePost = async (postId, userId) => {
    const postRef = doc(db, 'posts', postId);
    await runTransaction(db, async (transaction) => {
        const postDoc = await transaction.get(postRef);
        if (!postDoc.exists()) throw "Document does not exist!";

        let likes = postDoc.data().likes || [];
        if (likes.includes(userId)) {
            likes = likes.filter(id => id !== userId); // Unlike the post
        } else {
            likes.push(userId); // Like the post
        }

        transaction.update(postRef, { likes });
    });
};

export const addCommentToPost = async (postId, comment) => {
    const postRef = doc(db, 'posts', postId);

    await runTransaction(db, async (transaction) => {
        const postDoc = await transaction.get(postRef);
        if (!postDoc.exists()) throw "Document does not exist!";

        const comments = postDoc.data().comments || [];
        comments.push(comment);

        transaction.update(postRef, { comments });
    });
};


// Function to unlike a post
export const unlikePost = async (postId, userId) => {
    const postRef = doc(db, 'audioPosts', postId);

    // Use a transaction to ensure atomic update
    await runTransaction(db, async (transaction) => {
        const postDoc = await transaction.get(postRef);
        if (!postDoc.exists()) {
            throw "Document does not exist!";
        }

        const data = postDoc.data();
        // Remove the user from likes if they exist
        const likes = new Set(data.likes || []);
        likes.delete(userId);

        // Add the user to unlikes
        const unlikes = new Set(data.unlikes || []);
        unlikes.add(userId);

        transaction.update(postRef, { likes: Array.from(likes), unlikes: Array.from(unlikes) });
    });
};

const fetchTrendingAudioPosts = async () => {
    try {
        // Query to fetch posts ordered by the size of the 'likes' array in descending order, limit to 5
        const q = query(collection(db, 'audioPosts'), orderBy('likes', 'desc'), limit(5));
        const querySnapshot = await getDocs(q);
        return querySnapshot.docs.map(doc => ({id: doc.id, ...doc.data()}));
    } catch (error) {
        console.error('Error fetching trending audio posts: ', error);
        return [];
    }
};

 const fetchActiveFriends = async () => {
    try {
        const oneWeekAgo = new Date();
        oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

        // Assuming 'lastActivity' is a timestamp field in the 'users' document that gets updated with each post
        const q = query(collection(db, 'users'), where('lastActivity', '>=', oneWeekAgo));
        const querySnapshot = await getDocs(q);
        let activeFriends = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        // If no active friends, fetch any 5 friends as a fallback
        if (activeFriends.length === 0) {
            const fallbackQuery = query(collection(db, 'users'), limit(5));
            const fallbackSnapshot = await getDocs(fallbackQuery);
            activeFriends = fallbackSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        }

        return activeFriends;
    } catch (error) {
        console.error('Error fetching active friends: ', error);
        return [];
    }
};

// Function to upload a story
export const uploadStory = async (userId, file) => {
    const timestamp = new Date();
    const storyId = `${userId}_${timestamp.getTime()}`;
    const fileRef = ref(storage, `stories/${storyId}`);

    try {
        // Upload the file to Firebase Storage
        const snapshot = await uploadBytes(fileRef, file);
        const url = await getDownloadURL(snapshot.ref);

        // Save story metadata to Firestore
        await setDoc(doc(db, 'stories', storyId), {
            userId,
            mediaUrl: url,
            timestamp: timestamp, // Ensure this is compatible with Firestore Timestamp
            expiresAt: new Date(timestamp.getTime() + 24 * 60 * 60 * 1000) // 24 hours from now
        });

        console.log('Story uploaded successfully');
    } catch (error) {
        console.error('Error uploading story: ', error);
    }
};


// Function to fetch the current user's friends
const fetchFriends = async (userId) => {
    const friends = new Set(); // Use a Set to avoid duplicates

    try {
        // Query for friendships where the current user is either userId1 or userId2
        const q1 = query(collection(db, 'friendships'), where('userId1', '==', userId));
        const q2 = query(collection(db, 'friendships'), where('userId2', '==', userId));

        // Fetch both sets of friendships
        const [querySnapshot1, querySnapshot2] = await Promise.all([
            getDocs(q1),
            getDocs(q2),
        ]);

        // Add friend IDs to the set
        querySnapshot1.forEach((doc) => {
            friends.add(doc.data().userId2); // Add the other user in the friendship
        });
        querySnapshot2.forEach((doc) => {
            friends.add(doc.data().userId1); // Add the other user in the friendship
        });

        return friends; // Return the set of friend IDs
    } catch (error) {
        console.error("Error fetching friends: ", error);
        return new Set(); // Return an empty set in case of error
    }
};

// Function to fetch stories for the home page, including friend check logic
export const fetchStories = async (userId) => {
    const stories = [];
    const now = new Date();
    const friends = await fetchFriends(userId); // Fetch the current user's friends

    try {
        // Query for stories that haven't expired
        const q = query(collection(db, 'stories'), where('expiresAt', '>', now));
        const querySnapshot = await getDocs(q);

        querySnapshot.forEach((doc) => {
            const story = { id: doc.id, ...doc.data() };
            // Include the story if it's from the user or one of their friends
            if (story.userId === userId || friends.has(story.userId)) {
                stories.push(story);
            }
        });

        return stories;
    } catch (error) {
        console.error('Error fetching stories: ', error);
        return [];
    }
};


// Optional: Function to delete expired stories (could be run periodically as a scheduled Firebase Function)
export const deleteExpiredStories = async () => {
    const now = new Date();

    try {
        const q = query(collection(db, 'stories'), where('expiresAt', '<', now));
        const querySnapshot = await getDocs(q);

        querySnapshot.forEach(async (doc) => {
            await deleteDoc(doc.ref);
            console.log(`Deleted expired story: ${doc.id}`);
        });
    } catch (error) {
        console.error('Error deleting expired stories: ', error);
    }
};


export {
    auth,
    initializeApp,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    signInWithPopup,
    sendEmailVerification,
    GoogleAuthProvider,
    provider,
    FacebookAuthProvider,
    facebookProvider,
    signOut,
    db,
    collection,
    getSearchResult,
    sendFriendRequest,
    addDoc,
    confirmFriendRequest,
    denyFriendRequest,
    fetchFriendRequests,
    fetchFriendRequestsCount,
    fetchConfirmedFriends,
    fetchTrendingAudioPosts,
    fetchActiveFriends,
    getDoc,
    registerUser,
    userProfile,
    getUserProfile,
    uploadProfilePhotoFunc,
    uploadBannerPhotoFunc,
    sendPasswordResetEmail
};
