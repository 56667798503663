import React, {useEffect, useState} from 'react';
import logo from '../assets/login.PNG';
import {Link, useNavigate} from 'react-router-dom';
import {
    getDocs,
    collection,
    db,
    query,
    where,
    getSearchResult,
    fetchFriendRequestsCount,
    getUserProfile
} from "../firebase";
//icons
import { IoPeopleSharp } from "react-icons/io5";
import { FaPeopleCarry } from "react-icons/fa";
import { IoChatbox } from "react-icons/io5";
import { FaBell } from "react-icons/fa";
import { IoIosArrowDropdown } from "react-icons/io";

function NavBar() {
    const userId = localStorage.getItem('userId');
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [isSearchDropdownVisible, setIsSearchDropdownVisible] = useState(false);
    const [isInputFocused, setIsInputFocused] = useState(false);
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [userProfilePhoto, setUserProfilePhoto] = useState("");

    const [friendRequests, setFriendRequests] = useState([]);
    const [friendRequestsCount, setFriendRequestsCount] = useState(0);

    const navStyle = {
        backgroundColor: '#feb800',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px 50px 10px 50px',
        color: 'white'
    };

    const glassEffect = {
        backdropFilter: 'blur(4px)',
        backgroundColor: 'rgba(255,183,90,0.2)',
        color:'#383838',
        border: '1px solid rgba(255, 255, 255, 0.18)',
        borderRadius: '10px',
        marginTop: '18px',
        padding: '10px',
        position: 'absolute',
        right: '10px',
        top: '50px',
        zIndex: 1000, // Ensure dropdown is above other content
    };

    const searchGlassEffect = {
        backdropFilter: 'blur(4px)',
        backgroundColor: 'rgba(255,183,90,0.2)',
        color:'#383838',
        border: '1px solid rgba(255, 255, 255, 0.18)',
        borderRadius: '10px',
        marginTop: '2px', // Small gap between the input and dropdown
        padding: '10px',
        position: 'absolute',
        top: 68,
        left: 160, // Align to the left edge of the parent
        width: '38%', // Match the width of the parent
        zIndex: 1000, // Ensure dropdown is above other content
    };


    const circle = {
        width: '30px',
        height: '30px',
        borderRadius: '15px', // Makes the circle perfectly round
        backgroundImage: `url(${userProfilePhoto})`,
        backgroundColor: '#ebebeb',
        backgroundSize:'cover',
        backgroundPosition:'center',
    };

    const searchStyle = {
        flexGrow: 1,
        width:'80vh',
        marginLeft: '20px',
        marginRight: '20px',
        padding: '10px',
        borderRadius: '20px', // Rounded corners for the search bar
        border: 'none', // Removes the border
        outline: 'none', // Removes the outline on focus
    };

    const iconStyle = {
        cursor: 'pointer',
        margin: '0 10px'
    };

    const dropdownStyle = {
        ...iconStyle, // Inherits the base icon styles
        height: '30px',
        borderRadius: '20px', // Rounded corners for the dropdown
        border: 'none', // Removes the border
        outline: 'none', // Removes the outline on focus
        backgroundColor: 'transparent', // Sets the dropdown background color
        color: 'black',
        fontWeight: '800',
    };

    const toggleDropdown = () => {
        setIsDropdownVisible(!isDropdownVisible);
    };

    const handleFriends = () => {
        navigate('friend-request');
    }

    const handleProfile = () => {
        navigate(`user/${localStorage.getItem('userId')}`);
    }

    const handleHome = () => {
        navigate('/home');
    }

    useEffect(() => {
        const fetchUsers = async () => {
            if (searchQuery.length > 1) {
                try {
                    const results = await getSearchResult(searchQuery);
                    console.log("Search Results:", results); // Log the search results
                    setSearchResults(results);
                    setIsSearchDropdownVisible(results.length > 0 && isInputFocused);
                    if (results.length > 0) {
                        // Show the dropdown if there are search results
                        setIsSearchDropdownVisible(true);
                    } else {
                        // Hide the dropdown if there are no search results
                        setIsSearchDropdownVisible(false);
                    }
                } catch (error) {
                    console.error("Error fetching users: ", error);
                    setSearchResults([]); // Set to an empty array in case of error
                    setIsSearchDropdownVisible(false); // Hide the dropdown in case of error
                }
            } else {
                setSearchResults([]);
                setIsSearchDropdownVisible(false); // Hide the dropdown if the search query is cleared or too short
            }
        };
        fetchUsers();
    }, [searchQuery, isInputFocused]);

    useEffect(() => {
        (async () => {
            try{
                const profileData = await getUserProfile(userId);
                // Here you can set the state with the fetched profile data
                // For example, if you have a state for the user's about description:
                setUserProfilePhoto(profileData.profilePhotoUrl || '');
            }catch (error){
                console.error("Error fetching user profile: ",error);
            }
        })();
    }, [userId])

    useEffect(() => {
        const loadFriendRequestsCount = async () => {
            const count = await fetchFriendRequestsCount();
            setFriendRequestsCount(count);
        };

        loadFriendRequestsCount();
    }, []);

    const navigateToUserProfile = (userId) => {
        // Use React Router's navigate function or history.push
        setIsSearchDropdownVisible(false);
        navigate(`user/${userId}`);
        setSearchQuery('');
    };

    return (
        <div style={navStyle}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <img src={logo} alt="Logo" width='64px' style={{marginRight:'3%'}} onClick={handleHome}/>
                <input
                    style={searchStyle}
                    type="text"
                    placeholder="Search"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    onFocus={() => setIsInputFocused(true)}
                    onBlur={() => {
                        setTimeout(() => setIsInputFocused(false), 100);
                    }}
                />
                <div style={searchGlassEffect} hidden={!isInputFocused && searchResults.length === 0}>
                    {searchResults.length > 0 ? (
                        searchResults.map((user) => (
                            <div key={user.id} onClick={() => navigateToUserProfile(user.id)}>
                                {user.name}
                            </div>
                        ))
                    ) : (
                        <div>No results found</div>
                    )}
                </div>

            </div>
            <div className='row'>
                <div style={circle} onClick={handleProfile}></div>
                <i style={iconStyle} className="friends-icon" onClick={handleFriends}><IoPeopleSharp size={24}/>{friendRequestsCount > 0 && <span>{friendRequestsCount}</span>}</i>
                <i style={iconStyle}><FaPeopleCarry size={24}/></i>
                <i style={iconStyle} className="chats-icon"><IoChatbox size={24}/></i>
                <i style={iconStyle} className="notifications-icon"><FaBell size={24}/></i>
                <IoIosArrowDropdown size={24} style={iconStyle} onClick={toggleDropdown} />
                {isDropdownVisible && (
                    <div style={glassEffect}>
                        <ul style={{ listStyleType: 'none', padding: 0 }}>
                            <li>Create Page</li>
                            <li>Create Group</li>
                            <li>Manage Group</li>
                            <div className='ui form divider'></div>
                            <li>Create Ads</li>
                            <li>Advertising on FriendsModule</li>
                            <li>Activity Log</li>
                            <li>News Feed Preferences</li>
                            <div className='ui form divider'></div>
                            <li>Settings</li>
                            <Link style={{textDecoration:'none', color:'#383838'}} to='/'>Log Out</Link>
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
}

export default NavBar;
