import React, {useEffect, useState} from 'react';
import {FaPen, FaPlus, FaTimes, FaLinkedin, FaBriefcase, FaSchool, FaCamera} from 'react-icons/fa';
import Modal from 'react-modal';
import {userProfile, getUserProfile, uploadProfilePhotoFunc, uploadBannerPhotoFunc, sendFriendRequest} from "../firebase";
import {useNavigate, useParams} from "react-router-dom";
import {GiLovers} from "react-icons/gi";
import {collection, addDoc, db} from "../firebase";

// Custom styles for the modal
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '50%', // Width of the modal
    },
};

function IconWork() {
    return null;
}

function UserProfile() {
    const { userId: urlUserId } = useParams();
    const userId = urlUserId || localStorage.getItem('userId');
    const navigate = useNavigate();
    console.log("User Profile Id:",userId);

    const [hasChanges, setHasChanges] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isBioEditing, setIsBioEditing] = useState(false);
    const [isNameEditing, setIsNameEditing] = useState(false);
    const [isEditingExperience, setIsEditingExperience] = useState(false);
    const [currentUser, setCurrentUser] = useState(false);

    const [description, setDescription] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [bio, setBio] = useState('');

    // Location Variables
    // const [locationEditing, setLocationEditing] = useState(false);
    const [isLocationEditing, setIsLocationEditing] = useState(false);
    const [userLocation, setUserLocation] = useState({
        city:'',
        country:''
    });
    // Banner Photo Variables
    const [openBannerModal, setOpenBannerModal] = useState(false);
    const [userBannerPhoto, setUserBannerPhoto] = useState("");
    const [bannerPhoto, setBannerPhoto] = useState(null);

    // Profile Photo Variables
    const [openProfilePhotoModal, setOpenProfilePhotoModal] = useState(false);
    const [userProfilePhoto, setUserProfilePhoto] = useState("");
    const [profilePhoto, setProfilePhoto] = useState(null);

    // Experience
    const [modalIsOpen, setIsOpen] = useState(false);
    const [experiences, setExperiences] = useState([]);
    const [editingExperience, setEditingExperience] = useState(null);
    const [newExperience, setNewExperience] = useState({
        title: '',
        company: '',
        from: '',
        to: '',
        role: '',
        city: '',
        country: '',
        description:''
    });

    // Education
    const [educationModalIsOpen, setEducationModalIsOpen] = useState(false);
    const [educations, setEducations] = useState([]);
    const [editingEducation, setEditingEducation] = useState(null);
    const [newEducation, setNewEducation] = useState({
        school:'',
        from:'',
        to:'',
        degree:'',
        areaOfStudy:'',
        description:''
    })

    // Skills variables
    const [skillsModalIsOpen, setSkillsModalIsOpen] = useState(false);
    const [tags, setTags] = useState([]);
    const [input, setInput] = useState('');

    // Accomplishment Variables
    const [accomplishmentModalIsOpen, setAccomplishmentModalIsOpen] = useState(false);
    const [accomplishments, setAccomplishments] = useState([]);
    const [editingAccomplishment, setEditingAccomplishment] = useState(null);
    const [newAccomplishment, setNewAccomplishment] = useState({
        title:'',
        description:''
    })

    // Life Event Variables
    const [lifeEvents, setLifeEvents] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [lifeEventEditing, setLifeEventEditing] = useState(null);
    const [lifeEventModalIsOpen, setLifeEventModalIsOpen] = useState(false);
    const [lifeEventForm, setLifeEventForm] = useState({
        category: '',
        from: '',
        to: '',
        company: '',
        school: '',
        degree: '',
        who: '',
    });

    // Life event Functions

    const openLifeEventModal = () => {
        if (lifeEventEditing === null) {
            // Reset the form only if we're not editing an existing life event
            setLifeEventForm({
                category: '',
                from: '',
                to: '',
                company: '',
                school: '',
                degree: '',
                who: '',
            });
            setHasChanges(true);
        }
        setLifeEventModalIsOpen(true);
    };

    const openLifeEditModal = (event, index) => {
        setLifeEventEditing(index); // Store the index of the event being edited
        setLifeEventForm({ ...event }); // Populate the form with the event's current data
        openLifeEventModal();
        setHasChanges(true);
    };

    const closeLifeEventModal = () => {
        setLifeEventModalIsOpen(false);
        setLifeEventEditing(null);
        setNewExperience({
            category: '',
            from: '',
            to: '',
            company: '',
            schoolFrom:'',
            schoolTo: '',
            school: '',
            degree: '',
            who: '',
            relationshipFrom: '',
        });
    };

    const handleLifeEventInputChange = (e, field) => {
        // setLifeEventForm({ ...lifeEventForm, [field]: e.target.value });
        if (lifeEventEditing) {
            setLifeEventEditing({ ...lifeEventEditing, [field]: e.target.value });
            setHasChanges(true);
        } else {
            setLifeEventForm({ ...lifeEventForm, [field]: e.target.value });
        }
    };


    const handleLifeEventSubmit = () => {
        if (lifeEventEditing !== null) {
            // Update an existing life event
            const updatedLifeEvents = lifeEvents.map((item, index) => {
                if (index === lifeEventEditing) {
                    return { ...lifeEventForm };
                }
                return item;
            });
            setLifeEvents(updatedLifeEvents);
        } else {
            // Add a new life event
            const newEvent = { ...lifeEventForm };
            setLifeEvents([...lifeEvents, newEvent]);
        }
        closeLifeEventModal();
    };
    // Function to remove a life event
    const removeLifeEvent = (indexToRemove) => {
        setLifeEvents(lifeEvents.filter((_, index) => index !== indexToRemove));
        setHasChanges(true);
    };

    // Interest variables
    const interestsData = [
        {
            name: "The Vision Board",
            members: "13 members",
        },
        {
            name: "The Walt Disney Company",
            followers: "3,311,555 followers",
        },
        {
            name: "Mark Cuban",
            title: "President",
            followers: "5,519,235 followers",
        },
        {
            name: "Arianna Huffington",
            title: "Founder and CEO at Thrive Global",
            followers: "8,914,168 followers",
        },
    ];

    useEffect(() => {
        const userId = localStorage.getItem("userId");
        if (!userId) {
            navigate('/');
        }
    }, [navigate]);

    // Function to get data
    useEffect(() => {
        (async () => {
            try{
                const profileData = await getUserProfile(urlUserId);
                console.log(profileData); // Log the fetched data to the console

                // Here you can set the state with the fetched profile data
                // For example, if you have a state for the user's about description:
                setName(profileData.name || '');
                setEmail(profileData.email || '');
                setPhone(profileData.phone || '');
                setBio(profileData.bio || '');
                setUserProfilePhoto(profileData.profilePhotoUrl || '');
                setUserBannerPhoto(profileData.bannerUrl || '');
                setUserLocation(profileData.location || []);
                setLifeEvents(profileData.lifeEvents || []);
                setDescription(profileData.about_description);
                setExperiences(profileData.experience || []);
                setEducations(profileData.education || []);
                setTags(profileData.skills || []);
                setAccomplishments(profileData.accomplishments || []);

                // Check if the current user is viewing their own profile
                const loggedInUserId = localStorage.getItem('userId');
                if (urlUserId === loggedInUserId) {
                    setCurrentUser(true); // User is viewing their own profile
                } else {
                    setCurrentUser(false); // User is viewing someone else's profile
                }
            }catch (error){
                console.error("Error fetching user profile: ",error);
            }
        })();
    }, [urlUserId])

    // Accomplishment Function

    const handleAccomplishmentInputChange = (e, field) => {
        if (editingAccomplishment) {
            setEditingAccomplishment({ ...editingAccomplishment, [field]: e.target.value });
            setHasChanges(true);
        } else {
            setNewAccomplishment({ ...newAccomplishment, [field]: e.target.value });
        }
    };

    const accomplishmentOpenModal = () => {
        setAccomplishmentModalIsOpen(true);
        setHasChanges(true);
    };

    const accomplishmentCloseModal = () => {
        setAccomplishmentModalIsOpen(false);
        setEditingAccomplishment(null);
        setNewAccomplishment({ title: '', description: '' });
    };

    const saveAccomplishment = () => {
        if (editingAccomplishment) {
            setAccomplishments(accomplishments.map(exp => exp.id === editingAccomplishment.id ? editingAccomplishment : exp));
            setHasChanges(true);
        } else {
            const accomplishmentWithId = { ...newAccomplishment, id: accomplishments.length + 1 };
            setAccomplishments([...accomplishments, accomplishmentWithId]);
        }
        accomplishmentCloseModal();
    };

    const accomplishmentOpenEditModal = (accomplishment) => {
        setEditingAccomplishment(accomplishment);
        accomplishmentOpenModal();
    };


    // Skills Function
    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && input) {
            if (!tags.includes(input)) { // Prevent duplicates
                setTags([...tags, input]);
                setInput('');
            } else {
                alert('Duplicate entry'); // Or handle duplicates differently
            }
            e.preventDefault();
        }
    };

    const removeTag = (indexToRemove) => {
        setTags(tags.filter((_, index) => index !== indexToRemove));
        setHasChanges(true);
    };

    const openSkillsModal = () => {
        setSkillsModalIsOpen(true);
        setHasChanges(true);
    };

    const closeSkillsModal = () => {
        setSkillsModalIsOpen(false);
    };

    const saveSkills = () => {
        closeSkillsModal();
        // Here you can handle the saved skills, for example, sending them to a backend server or storing in a global state
    };



    // Education
    const educationOpenModal = () => {
        setEducationModalIsOpen(true);
        setHasChanges(true);
    };

    // Function to close the modal
    const educationCloseModal = () => {
        setEducationModalIsOpen(false);
        setEditingEducation(null); // Reset editing experience when modal closes
        setNewEducation({ school: '', from: '', to: '', degree: '', areaOfStudy: '', description: '' }); // Reset new experience form
    };

    const openModal = () => {
        setIsOpen(true);
        setHasChanges(true)
    };

    // Function to close the modal
    const closeModal = () => {
        setIsOpen(false);
        setEditingExperience(null); // Reset editing experience when modal closes
        setNewExperience({ title: '', company: '', from: '', to: '', role: '', city: '', country: '' }); // Reset new experience form
    };

    // Experience
    const handleInputChange = (e, field) => {
        if (editingExperience) {
            setEditingExperience({ ...editingExperience, [field]: e.target.value });
            setHasChanges(true);
        } else {
            setNewExperience({ ...newExperience, [field]: e.target.value });
        }
    };

    const handleInputLocationChange = (e, field) => {

        setUserLocation({ ...userLocation, [field]: e.target.value });
    };

    const saveExperience = () => {
        if (editingExperience) {
            setExperiences(experiences.map(exp => exp.id === editingExperience.id ? editingExperience : exp));
            setHasChanges(true);
        } else {
            const experienceWithId = { ...newExperience, id: experiences.length + 1 };
            setExperiences([...experiences, experienceWithId]);
        }
        closeModal();
    };

    // Function to handle education input change in the modal form
    const handleEducationInputChange = (e, field) => {
        if (editingEducation) {
            setEditingEducation({ ...editingEducation, [field]: e.target.value });
            setHasChanges(true);
        } else {
            setNewEducation({ ...newEducation, [field]: e.target.value });
        }
    };

    const saveEducation = () => {
        if (editingEducation) {
            setEducations(educations.map(exp => exp.id === editingEducation.id ? editingEducation : exp));
            setHasChanges(true);
        } else {
            const educationWithId = { ...newEducation, id: educations.length + 1 };
            setEducations([...educations, educationWithId]);
        }
        educationCloseModal();
    };

    // About
    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    };

    const toggleEdit = () => {
        setIsEditing(!isEditing);
        setHasChanges(true);
    };

    const toggleBioEdit = () => {
        setIsBioEditing(!isBioEditing);
        setHasChanges(true);
    };
    const toggleNameEdit = () => {
        setIsNameEditing(!isNameEditing);
        setHasChanges(true);
    };

    const toggleLocationEdit = () => {
        setIsLocationEditing(!isLocationEditing);
        setHasChanges(true);
    };

    const openEditModal = (experience) => {
        setEditingExperience(experience);
        setHasChanges(true);
        openModal();
    };
    const educationOpenEditModal = (education) => {
        setEditingEducation(education);
        setHasChanges(true);
        educationOpenModal();
    };

    // Save All Changes
    const handleSaveProfileChanges = async(e) => {
        e.preventDefault();
        try{
            await userProfile(userId, name, email, phone, bio, userLocation, lifeEvents,description, experiences, educations, tags, accomplishments);
            console.log('successful')
        }catch (e){
            console.error('error: ',e);
        }
    }

    const handleBannerChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setBannerPhoto(file);
        }
    };

    const handleProfilePhotoChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setProfilePhoto(file);
        }else{
            console.log("Error undefined image");
        }
    };

    const uploadProfilePhoto = async(e) => {
        e.preventDefault();
        try {
            await uploadProfilePhotoFunc(userId,profilePhoto);
            console.log('Success');
            setOpenProfilePhotoModal(false);
        }catch(e){
            console.error(e);
        }
    };
    const uploadBannerImage = async (e) => {
        e.preventDefault();
        try{
            await uploadBannerPhotoFunc(userId, bannerPhoto);
            console.log('success banner');
            setOpenBannerModal(false);
        }catch(e){
            console.error(e);
        }
    };

    return (
        <div className='page-style'>
            <div className='page-container'>
                <div className='banner'  style={{backgroundImage:`url(${userBannerPhoto})`}}>
                    {currentUser && (
                        <FaPen onClick={() => setOpenBannerModal(true)} color='white' style={{position:'relative', top:'90%', left: '97%'}}/>
                    )}
                </div>
                <div className='profilePhoto' style={{backgroundImage:`url(${userProfilePhoto})`}}>
                    {currentUser && (
                        <FaCamera onClick={()=>setOpenProfilePhotoModal(true)} size={24} color='#ebebeb' style={{position:'relative', top:'30%', left: '23%'}}/>
                    )}
                </div>
                { !currentUser && (
                    <button onClick={() => sendFriendRequest(userId)}>Send Friend Request</button>
                )}

                {/* Modal for updating the banner image */}
                {openBannerModal && (
                    <Modal isOpen={openBannerModal} onRequestClose={() => setOpenBannerModal(false)} style={customStyles}>
                        <h2>Change Banner</h2>
                        <input type="file" onChange={handleBannerChange} />
                        <div className='row'>
                            <button className='ui yellow button' onClick={uploadBannerImage}>Upload</button>
                            <button className='ui button' onClick={()=>setOpenBannerModal(false)}>Cancel</button>
                        </div>
                    </Modal>
                )}

                {/* Modal for updating the profile photo */}
                {openProfilePhotoModal && (
                    <Modal isOpen={openProfilePhotoModal} onRequestClose={() => setOpenProfilePhotoModal(false)} style={customStyles}>
                        <h2>Change Profile Photo</h2>
                        <input type="file" onChange={handleProfilePhotoChange} />
                        <button className='ui yellow button' onClick={uploadProfilePhoto}>Upload</button>
                        <button className='ui button' onClick={()=>setOpenProfilePhotoModal(false)}>Cancel</button>
                    </Modal>
                )}
                {isNameEditing ? (
                    <div className='ui form'>
                        <div className='field'>
                            <input type='text' placeholder='Enter Username' value={name} onChange={(e)=> setName(e.target.value)}/>
                        </div>
                        <button className='ui yellow button' style={{marginBottom:'20px'}} onClick={toggleNameEdit}>Save</button>
                    </div>
                ) : (
                    <div className='row'>
                        <h3>{name || 'Username'}</h3>
                        {currentUser && (
                            <FaPen onClick={toggleNameEdit}/>
                        )}
                    </div>
                )}

                {isBioEditing ? (
                    <div>
                        <textarea
                            className='description-about'
                            value={bio}
                            style={{minHeight:'100px'}}
                            onChange={(e)=> setBio(e.target.value)}
                            placeholder='Describe about you'
                        />
                        <button style={{marginBottom:'20px'}} onClick={toggleBioEdit}>Save</button>
                    </div>
                ) : (

                    <div className='row'>
                        <p>{bio || 'Your Bio'}</p>
                        {currentUser ?? (
                            <FaPen  onClick={toggleBioEdit}/>
                        )}
                    </div>
                )}

                {isLocationEditing ? (
                    <div className='ui form'>
                        <div className='field'>
                            <input
                                type='text'
                                value={userLocation.city || ''}
                                onChange={(e) => handleInputLocationChange(e,'city')}
                                placeholder='City'
                            />
                            <input
                                type='text'
                                value={userLocation.country || ''}
                                onChange={(e)=>handleInputLocationChange(e,'country')}
                                placeholder='Country'
                            />
                        </div>
                        <button className='ui button' onClick={toggleLocationEdit}>Submit</button>
                    </div>
                ) : (
                    <p>
                        {userLocation.city && userLocation.country ? `${userLocation.city}.${userLocation.country}` : 'Location.Location'}
                        <span>500+ Connections</span>
                        {currentUser && (
                            <FaPen onClick={toggleLocationEdit}/>
                        )}
                    </p>

                )}

                {/* Life Events */}
                <div className='ui divider'></div>
                <div className='profile-row'>
                    <h2>Life Events</h2>
                    {currentUser && (
                        <FaPlus onClick={openLifeEventModal} />
                    )}
                </div>
                <div className='life-event'>
                    {lifeEvents.length === 0 ? (
                        <p>No life events</p>
                    ) : (
                        lifeEvents.map((event, index) => (
                            <div key={index}>
                                {event.category === 'work' && (
                                    <div className='life-event-component'>
                                        <FaBriefcase size={32}/> {/* Replace IconWork with your work icon component */}
                                        <p>{event.from}-{event.to}</p>
                                        <p>{event.company}</p>
                                        <div className='row' style={{justifyContent:'space-around', opacity:'0.5'}}>
                                            <FaPen onClick={() => openLifeEditModal(event, index)}/>
                                            <FaTimes onClick={() => removeLifeEvent(index)}/>
                                        </div>
                                    </div>
                                )}
                                {event.category === 'education' && (
                                    <div className='life-event-component' style={{textAlign:'center'}}>
                                        <FaSchool size={32}/> {/* Replace IconWork with your work icon component */}
                                        <p>{event.schoolFrom}-{event.schoolTo}</p>
                                        <p>{event.school}</p>
                                        <div className='row' style={{justifyContent:'space-around', opacity:'0.5'}}>
                                            <FaPen onClick={() => openLifeEditModal(event, index)}/>
                                            <FaTimes onClick={() => removeLifeEvent(index)}/>
                                        </div>
                                    </div>
                                )}
                                {event.category === 'relationship' && (
                                    <div className='life-event-component'>
                                        <GiLovers size={32}/> {/* Replace IconWork with your work icon component */}
                                        <p>{event.relationshipFrom}</p>
                                        <p>{event.who}</p>
                                        <div className='row' style={{justifyContent:'space-around', opacity:'0.5'}}>
                                            {currentUser && (
                                                <>
                                                    <FaPen onClick={() => openLifeEditModal(event, index)}/>
                                                    <FaTimes onClick={() => removeLifeEvent(index)}/>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))
                    )}
                </div>
                {/* Life Event Modal */}
                <Modal
                    isOpen={lifeEventModalIsOpen}
                    onRequestClose={closeLifeEventModal}
                    style={customStyles}
                    contentLabel='Life Event Modal'
                >
                    <h2>Add Life Event</h2>
                    <form className='ui form'>
                        <div className='field'>
                            <label>Category</label>
                            <select
                                value={lifeEventForm.category}
                                onChange={(e) => {
                                    setSelectedCategory(e.target.value);
                                    handleLifeEventInputChange(e, 'category');
                                }}
                            >
                                <option value=''>Select category</option>
                                <option value='work'>Work</option>
                                <option value='education'>Education</option>
                                <option value='relationship'>Relationship</option>
                                {/* Add more options as needed */}
                            </select>
                        </div>
                        {/* Render form fields based on selected category */}
                        {selectedCategory === 'work' && (
                            <>
                                <div className='field'>
                                    <label>From</label>
                                    <input
                                        type='text'
                                        value={lifeEventForm.from}
                                        onChange={(e) => handleLifeEventInputChange(e, 'from')}
                                    />
                                </div>
                                <div className='field'>
                                    <label>To</label>
                                    <input
                                        type='text'
                                        value={lifeEventForm.to}
                                        onChange={(e) => handleLifeEventInputChange(e, 'to')}
                                    />
                                </div>
                                <div className='field'>
                                    <label>Company</label>
                                    <input
                                        type='text'
                                        value={lifeEventForm.company}
                                        onChange={(e) => handleLifeEventInputChange(e, 'company')}
                                    />
                                </div>
                                {/* Add more fields for work category */}
                            </>
                        )}
                        {selectedCategory === 'education' && (
                            <>
                                <div className='field'>
                                    <label>From</label>
                                    <input
                                        type='text'
                                        value={lifeEventForm.schoolFrom}
                                        onChange={(e) => handleLifeEventInputChange(e, 'schoolFrom')}
                                    />
                                </div>
                                <div className='field'>
                                    <label>To</label>
                                    <input
                                        type='text'
                                        value={lifeEventForm.schoolTo}
                                        onChange={(e) => handleLifeEventInputChange(e, 'schoolTo')}
                                    />
                                </div>
                                <div className='field'>
                                    <label>School</label>
                                    <input
                                        type='text'
                                        value={lifeEventForm.school}
                                        onChange={(e) => handleLifeEventInputChange(e, 'school')}
                                    />
                                </div>
                                {/* Add more fields for education category */}
                            </>
                        )}
                        {selectedCategory === 'relationship' && (
                            <>
                                <div className='field'>
                                    <label>From</label>
                                    <input
                                        type='text'
                                        value={lifeEventForm.relationshipFrom}
                                        onChange={(e) => handleLifeEventInputChange(e, 'relationshipFrom')}
                                    />
                                </div>
                                <div className='field'>
                                    <label>Who</label>
                                    <input
                                        type='text'
                                        value={lifeEventForm.who}
                                        onChange={(e) => handleLifeEventInputChange(e, 'who')}
                                    />
                                </div>
                                {/* Add more fields for relationship category */}
                            </>
                        )}
                        {/* Add more categories as needed */}
                        <button
                            className='ui yellow button'
                            type='button'
                            onClick={handleLifeEventSubmit}
                        >
                            Save
                        </button>
                        <button
                            className='ui button'
                            type='button'
                            onClick={closeLifeEventModal}
                        >
                            Cancel
                        </button>
                    </form>
                </Modal>

                <div className='ui divider'></div>
                {/*   About   */}
                <h2>About</h2>
                {isEditing ? (
                    <div>
                        <textarea
                            className='description-about'
                            value={description}
                            onChange={handleDescriptionChange}
                            rows='4'
                            placeholder='Describe about you'
                        />
                        <button onClick={toggleEdit}>Save</button>
                    </div>
                ) : (
                    <div>
                        <p>{description || 'Describe About you'}</p>
                        {currentUser && (
                            <FaPen onClick={toggleEdit} />
                        )}
                    </div>
                )}
                <div className='ui divider'></div>
                {/*  Experience  */}
                <div className='profile-row'>
                    <h2>Experience</h2>
                    {currentUser && (
                        <FaPlus onClick={openModal}/>
                    )}
                </div>
                {experiences.map((exp, index) => (
                    <div key={index} className='experience-display'>
                        <p>{exp.title} | {exp.role}</p>
                        <p>{exp.company}</p>
                        <p>{exp.from} - {exp.to}</p>
                        <p>{exp.city}, {exp.country}</p>
                        {currentUser && (<FaPen onClick={() => openEditModal(exp)} />)}
                    </div>
                ))}
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel='Experience Modal'
                >
                    <h2>{editingExperience ? 'Edit Experience' : 'Add Experience'}</h2>
                    <form className='ui form'>
                        <div className='field'>
                            <div className='two fields'>
                                <div className='seven wide field'>
                                    <input name="title" value={editingExperience ? editingExperience.title : newExperience.title} onChange={(e) => handleInputChange(e, 'title')} placeholder="Title" />
                                </div>
                                <div className='seven wide field'>
                                    <input name="company" value={editingExperience ? editingExperience.company : newExperience.company} onChange={(e) => handleInputChange(e, 'company')} placeholder="Company" />
                                </div>
                            </div>
                            <div className='three fields'>
                                <div className='three wide field'>
                                    <input name="from" value={editingExperience ? editingExperience.from : newExperience.from} onChange={(e) => handleInputChange(e, 'from')} placeholder="From" />
                                </div>
                                <div className='three wide field'>
                                    <input name="to" value={editingExperience ? editingExperience.to : newExperience.to} onChange={(e) => handleInputChange(e, 'to')} placeholder="To" />
                                </div>
                                <div className='eight wide field'>
                                    <input name="role" value={editingExperience ? editingExperience.role : newExperience.role} onChange={(e) => handleInputChange(e, 'role')} placeholder="Role" />
                                </div>
                            </div>
                            <div className='two fields'>
                                <div className='seven wide field'>
                                    <input name="city" value={editingExperience ? editingExperience.city : newExperience.city} onChange={(e) => handleInputChange(e, 'city')} placeholder="City" />
                                </div>
                                <div className='seven wide field'>
                                    <input name="country" value={editingExperience ? editingExperience.country : newExperience.country} onChange={(e) => handleInputChange(e, 'country')} placeholder="Country" />
                                </div>
                            </div>
                        </div>
                        <button className='ui yellow button' type='button' onClick={saveExperience}>Save</button>
                        <button className='ui  button' type='button' onClick={closeModal}>Cancel</button>
                    </form>
                </Modal>
                {/*  Education  */}
                <div className='ui divider'></div>
                <div className='profile-row'>
                    <h2>Education</h2>
                    {currentUser && (
                        <FaPlus onClick={educationOpenModal}/>
                    )}
                </div>
                {educations.map((edu, index) => (
                    <div key={index} className='experience-display'>
                        <h5 style={{margin:0}}>{edu.school}</h5>
                        <h6 style={{marginTop:0}}>{edu.degree}</h6>
                        <p>{edu.from} - {edu.to}</p>
                        <p>{edu.areaOfStudy}</p>
                        <p>{edu.description}</p>
                        {currentUser && (<FaPen onClick={() => educationOpenEditModal(edu)} />)}
                    </div>
                ))}
                <Modal
                    isOpen={educationModalIsOpen}
                    onRequestClose={educationCloseModal}
                    style={customStyles}
                    contentLabel='Education Modal'
                >
                    <h2>{editingEducation ? 'Edit Education' : 'Add Education'}</h2>
                    <form className='ui form'>
                        <form className='ui form'>
                            <div className='field'>
                                <label>School</label>
                                <input
                                    name="school"
                                    value={editingEducation ? editingEducation.school : newEducation.school}
                                    onChange={(e) => handleEducationInputChange(e, 'school')}
                                    placeholder="School"
                                />
                            </div>
                            <div className='field'>
                                <label>Degree</label>
                                <input
                                    name="degree"
                                    value={editingEducation ? editingEducation.degree : newEducation.degree}
                                    onChange={(e) => handleEducationInputChange(e, 'degree')}
                                    placeholder="Degree"
                                />
                            </div>
                            <div className='field'>
                                <label>Area of Study</label>
                                <input
                                    name="areaOfStudy"
                                    value={editingEducation ? editingEducation.areaOfStudy : newEducation.areaOfStudy}
                                    onChange={(e) => handleEducationInputChange(e, 'areaOfStudy')}
                                    placeholder="Area of Study"
                                />
                            </div>
                            <div className='field'>
                                <label>From</label>
                                <input
                                    name="from"
                                    type="text"
                                    value={editingEducation ? editingEducation.from : newEducation.from}
                                    onChange={(e) => handleEducationInputChange(e, 'from')}
                                />
                            </div>
                            <div className='field'>
                                <label>To</label>
                                <input
                                    name="to"
                                    type="text"
                                    value={editingEducation ? editingEducation.to : newEducation.to}
                                    onChange={(e) => handleEducationInputChange(e, 'to')}
                                />
                            </div>
                            <div className='field'>
                                <label>Description</label>
                                <textarea
                                    name="description"
                                    value={editingEducation ? editingEducation.description : newEducation.description}
                                    onChange={(e) => handleEducationInputChange(e, 'description')}
                                    placeholder="Description"
                                />
                            </div>
                            <button className='ui button yellow' type='button' onClick={saveEducation}>Save</button>
                            <button className='ui button' type='button' onClick={educationCloseModal}>Cancel</button>
                        </form>
                    </form>
                </Modal>

                {/* Skills */}
                <div className='ui divider'></div>
                <div className='profile-row'>
                    <h2>Skills</h2>
                    {currentUser && (
                        <FaPlus onClick={openSkillsModal} />
                    )}
                </div>
                <ul>
                    {tags.map((tag, index) => (
                        <li key={index} className='row'>
                            {tag} {currentUser && (<FaTimes style={{opacity:'0.5'}} onClick={() => removeTag(index)} />)}
                        </li>
                    ))}
                </ul>
                {/* Skills Modal */}
                <Modal
                    isOpen={skillsModalIsOpen}
                    onRequestClose={closeSkillsModal}
                    style={customStyles}
                    contentLabel='Skills Modal'
                >
                    <h2>Add Skills</h2>
                    <div className="tag-input">
                        <ul>
                            {tags.map((tag, index) => (
                                <li key={index}>
                                    {tag} <FaTimes onClick={() => removeTag(index)} />
                                </li>
                            ))}
                        </ul>
                        <input
                            type="text"
                            value={input}
                            onChange={(e) => setInput(e.target.value)}
                            onKeyDown={handleKeyDown}
                            placeholder="Type a skill and hit enter"
                        />
                    </div>
                    <button className='ui button yellow' type='button' onClick={saveSkills}>Save</button>
                    <button className='ui button' type='button' onClick={closeSkillsModal}>Cancel</button>
                </Modal>

                {/* Accomplishments */}
                <div className='ui divider'></div>
                <div className='profile-row'>
                    <h2>Accomplishments</h2>
                    {currentUser && (
                        <FaPlus onClick={accomplishmentOpenModal} />
                    )}
                </div>
                {accomplishments.map((acc, index) => (
                    <div key={index} className='experience-display'>
                        <h4>{acc.title}</h4>
                        <p>{acc.description}</p>
                        {currentUser && (
                            <FaPen onClick={() => accomplishmentOpenEditModal(acc)} />
                        )}
                    </div>
                ))}
                <Modal
                    isOpen={accomplishmentModalIsOpen}
                    onRequestClose={accomplishmentCloseModal}
                    style={customStyles}
                    contentLabel='Accomplishment Modal'
                >
                    <form className='ui form'>
                        <div className='field'>
                            <input
                                name="title"
                                value={editingAccomplishment ? editingAccomplishment.title : newAccomplishment.title}
                                onChange={(e) => handleAccomplishmentInputChange(e, 'title')} placeholder="Title" />
                        </div>
                        <div className='field'>
                            <textarea
                                name="description"
                                value={editingAccomplishment ? editingAccomplishment.description : newAccomplishment.description}
                                onChange={(e) => handleAccomplishmentInputChange(e, 'description')} placeholder="Description" />
                        </div>
                        <button className='ui button yellow' type='button' onClick={saveAccomplishment}>Save</button>
                        <button className='ui button' type='button' onClick={accomplishmentCloseModal}>Cancel</button>
                    </form>
                </Modal>

                {/*Interest*/}
                <div className='ui divider'></div>
                <div className="interests-section">
                    <h2>Interests</h2>
                    <div className="interests-list">
                        {interestsData.map((interest, index) => (
                            <div className="interest-item" key={index}>
                                <FaLinkedin className="linkedin-icon" />
                                <div className="interest-info">
                                    <p className="interest-name">{interest.name}</p>
                                    <p className="interest-detail">
                                        {interest.title ? `${interest.title} | ` : ""}
                                        {interest.members || interest.followers}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <button className='ui yellow button' onClick={handleSaveProfileChanges} disabled={!hasChanges}>Save Changes</button>
            </div>
        </div>
    );
}

export default UserProfile;