import React from 'react';
import {Outlet, Link} from "react-router-dom";
import NavBar from "../components/NavBar";

function Layout() {
    return (
        <div>
            <div className='navbar'>
                <NavBar/>
            </div>
            <div className='main-content'>
                <Outlet/>
            </div>

        </div>
    )
}

export default Layout
